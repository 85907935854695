import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ActiveOrInactiveStatusIconComponent } from '@app/@pso-ui/active-or-inactive-status-icon/active-or-inactive-status-icon.component';
import { AddressComponent } from '@app/@pso-ui/address/address.component';
import { AttachmentComponent } from '@app/@pso-ui/attachment/attachment.component';
import { AttachmentsOrEmptyAttachmentComponent } from '@app/@pso-ui/attachments-or-empty-attachment/attachments-or-empty-attachment.component';
import { BadgeComponent } from '@app/@pso-ui/badge/badge.component';
import { ButtonDropdownComponent } from '@app/@pso-ui/button-dropdown/button-dropdown.component';
import { PsoButtonModule } from '@app/@pso-ui/button-pso/pso-button.module';
import { ButtonWarnArrowLeftComponent } from '@app/@pso-ui/buttons/button-warn-arrow-left/button-warn-arrow-left.component';
import { ButtonWarnArrowRightComponent } from '@app/@pso-ui/buttons/button-warn-arrow-right/button-warn-arrow-right.component';
import { AnchorTargetDirective } from '@app/@pso-ui/directives/anchor-target.directive';
import { DragAndDropComponent } from '@app/@pso-ui/drag-and-drop/drag-and-drop.component';
import { EllipsisComponent } from '@app/@pso-ui/ellipsis/ellipsis.component';
import { EmptyStateComponent } from '@app/@pso-ui/empty-state/empty-state.component';
import { ExpectedDatePickerComponent } from '@app/@pso-ui/expected-date-picker/expected-date-picker.component';
import { FiltersSectionComponent } from '@app/@pso-ui/filters-section/filters-section.component';
import { FiltersSelectComponent } from '@app/@pso-ui/filters-select/filters-select.component';
import { InputSuffixComponent } from '@app/@pso-ui/input-suffix/input-suffix.component';
import { ModalTextComponent } from '@app/@pso-ui/modal-text/modal-text.component';
import { NewTyreBadgeComponent } from '@app/@pso-ui/new-tyre-badge/new-tyre-badge.component';
import { NotifySnackbarComponent } from '@app/@pso-ui/notify-snackbar/notify-snackbar.component';
import { PriceComponent } from '@app/@pso-ui/price/price.component';
import { RimTypeComponent } from '@app/@pso-ui/rim-type/rim-type.component';
import { SelectReadonlyComponent } from '@app/@pso-ui/select-readonly/select-readonly.component';
import { SeparatorComponent } from '@app/@pso-ui/separator/separator.component';
import { SortableHeaderComponent } from '@app/@pso-ui/sortable-header/sortable-header.component';
import { StepsComponent } from '@app/@pso-ui/steps/steps.component';
import { TabItemComponent } from '@app/@pso-ui/tab-panel/tab-item/tab-item.component';
import { TableNoEntriesComponent } from '@app/@pso-ui/table-no-entries/table-no-entries.component';
import { TextareaWithLimitationComponent } from '@app/@pso-ui/textarea-with-limitation/textarea-with-limitation.component';
import { PsoTypeaheadModule } from '@app/@pso-ui/typeahead/pso-typeahead.module';
import { ActiveTireInteractiveSvgComponent } from '@app/@pso-ui/tyre-active-interactive-svg/active-tire-interactive-svg.component';
import { TyreAdhesionComponent } from '@app/@pso-ui/tyre-adhesion/tyre-adhesion.component';
import { TyreDataComponent } from '@app/@pso-ui/tyre-data/tyre-data.component';
import { TyreFuelEfficientComponent } from '@app/@pso-ui/tyre-fuel-efficient/tyre-fuel-efficient.component';
import { TyreNoiseLevelComponent } from '@app/@pso-ui/tyre-noise-level/tyre-noise-level.component';
import { TyreParamsComponent } from '@app/@pso-ui/tyre-params/tyre-params.component';
import { TirePositionDesignationComponent } from '@app/@pso-ui/tyre-position-designation-icon/tire-position-designation.component';
import { TyreSeasonComponent } from '@app/@pso-ui/tyre-season/tyre-season.component';
import { TreadDepthInputComponent } from '@app/@pso-ui/tyre-tread-depth-input/tread-depth-input.component';
import { TreadDepthComponent } from '@app/@pso-ui/tyre-tread-depth/tread-depth.component';
import { TyreTypeIconComponent } from '@app/@pso-ui/tyre-type-icon/tyre-type-icon.component';
import { UserRolesComponent } from '@app/@pso-ui/user-roles/user-roles.component';
import { FleetPlateComponent } from '@app/@pso-ui/vehicle-fleet-plate/fleet-plate.component';
import { VehicleTyreDefaultsProducerComponent } from '@app/@pso-ui/vehicle-tyre-defaults-producer/vehicle-tyre-defaults-producer.component';
import { VehicleTyreDefaultsSegmentComponent } from '@app/@pso-ui/vehicle-tyre-defaults-segment/vehicle-tyre-defaults-segment.component';
import { VehicleTyreDefaultsTypeComponent } from '@app/@pso-ui/vehicle-tyre-defaults-type/vehicle-tyre-defaults-type.component';
import { VipBadgeComponent } from '@app/@pso-ui/vip-badge/vip-badge.component';
import { WchfsUiModule } from '@app/@wchfs-ui/lib/wchfs-ui.module';
import { CollectiveCheckboxComponent } from '@pso-ui/collective-checkbox/collective-checkbox.component';
import { DepositActionStatusComponent } from '@pso-ui/deposit-action-status/deposit-action-status.component';
import { OrderStatusComponent } from '@pso-ui/order-status/order-status.component';
import { PrintOrEmptyPrintComponent } from '@pso-ui/print-or-empty-print/print-or-empty-print.component';
import { PrintComponent } from '@pso-ui/print/print.component';
import { RimTypeSelectComponent } from '@pso-ui/rim-select/rim-type-select.component';
import { SelectModule } from '@pso-ui/select/select.module';
import { ToTyreStateResourcePipe } from '@pso-ui/to-tyre-state-resource.pipe';
import { AddressWithCoordinatesComponent } from './addres-with-coordinates/address-with-coordinates.component';
import { CoordinatesComponent } from './coordinates/coordinates.component';
import { DepositPaymentStatusComponent } from './deposit-payment-status/deposit-payment-status.component';
import { DataCyDirective } from './directives/data-cy.directive';
import { DndDirective } from './directives/dnd.directive';
import { PreventDoubleClickDirective } from './directives/prevent-double-click.directive';
import { SkipNavigationOnSelectionDirective } from './directives/skip-navigation-on-selection.directive';
import { TodoDirective } from './directives/todo.directive';
import { DiscountComponent } from './discount/discount.component';
import { TyreInfoComponent } from './tyre-info/tyre-info.component';
import { DotInputComponent } from './dot-input/dot-input.component';
import { DotLoaderModule } from './dot-loader/dot-loader.module';
import { HeaderAndBoldDataComponent } from './header-and-bold-data/header-and-bold-data.component';
import { MarginComponent } from './margin/margin.component';
import { GenericModalComponent } from './modal/generic-modal/generic-modal.component';
import { ModalModule } from './modal/modal.module';
import { RecordsInfoComponent } from './records-info/records-info.component';
import { RemarksComponent } from './remarks/remarks.component';
import { StatusComponent } from './status/status.component';
import { TabPanelComponent } from './tab-panel/tab-panel.component';
import { TopActionsBarComponent } from './top-actions-bar/top-actions-bar.component';
import { FourWheelsActiveTireInteractiveSvgComponent } from './tyre-active-interactive-svg/four-wheels-active-tire-interactive-svg/four-wheels-active-tire-interactive-svg.component';
import { SixWheelsActiveTireInteractiveSvgComponent } from './tyre-active-interactive-svg/six-wheels-active-tire-interactive-svg/six-wheels-active-tire-interactive-svg.component';
import { VehicleTypeSingleSelectComponent } from '@app/@feed-inputs/vehicles/vehicle-type-single-select/vehicle-type-single-select.component';
import { CounterComponent } from './counter/counter.component';
import { CompleteWheelComponent } from './complete-wheel/complete-wheel.component';
import { ProductDataComponent } from './product-data/product-data.component';
import { CompletionTypeComponent } from './completion-type/completion-type.component';
import { CopyToClipboardDirective } from './directives/copy-to-clipboard.directive';
import { ScrollTargetDirective } from './scroll-target.directive';
import { TableLoaderOrEmptyStateComponent } from './table-loader-or-empty-state/table-loader-or-empty-state.component';
import { PaginationAboveTableComponent } from './pagination-above-table/pagination-above-table.component';
import { PaginationUnderTableComponent } from './pagination-under-table/pagination-under-table.component';
import { FullWheelLabelComponent } from './full-wheel-label/full-wheel-label.component';
import { DetailsLoaderStateComponent } from './details-loader-state/details-loader-state.component';
import { GeneralContentStateComponent } from '@pso-ui/general-content-state/general-content-state.component';
import { VehicleHistoryTabComponent } from './vehicle-history-tab/vehicle-history-tab.component';
import { VehicleHistoryTabPropertiesComponent } from './vehicle-history-tab/vehicle-history-tab-properties/vehicle-history-tab-properties.component';
import { VehicleSearchInputComponent } from './vehicle-search-input/vehicle-search-input.component';
import { FullWidthSeparatorModule } from '@pso-ui/full-width-separator/full-width-separator.module';
import { HeadingModule } from '@pso-ui/heading/heading.module';
import { BlueGradientBadgeComponent } from '@pso-ui/blue-gradient-badge/blue-gradient-badge.component';
import { DayCardComponent } from '@pso-ui/day-card/day-card.component';
import { MonthSelectorComponent } from '@pso-ui/month-selector/month-selector.component';
import { TimelineItemComponent } from '@pso-ui/timeline-item/timeline-item.component';
import { LinkComponent } from './link/link.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DropAreaComponent } from '@pso-ui/drop-area/drop-area.component';

@NgModule({
  declarations: [
    TopActionsBarComponent,
    RecordsInfoComponent,
    TabPanelComponent,
    TabItemComponent,
    StatusComponent,
    RemarksComponent,
    AttachmentComponent,
    StepsComponent,
    EllipsisComponent,
    SortableHeaderComponent,
    InputSuffixComponent,
    SeparatorComponent,
    FiltersSectionComponent,
    FiltersSelectComponent,
    VipBadgeComponent,
    ButtonWarnArrowLeftComponent,
    ButtonWarnArrowRightComponent,
    ButtonDropdownComponent,
    PriceComponent,
    RimTypeComponent,
    AttachmentsOrEmptyAttachmentComponent,
    TirePositionDesignationComponent,
    TreadDepthInputComponent,
    NotifySnackbarComponent,
    TyreFuelEfficientComponent,
    TyreAdhesionComponent,
    TyreNoiseLevelComponent,
    TableNoEntriesComponent,
    ActiveOrInactiveStatusIconComponent,
    FleetPlateComponent,
    BadgeComponent,
    DragAndDropComponent,
    AddressComponent,
    SelectReadonlyComponent,
    ModalTextComponent,
    UserRolesComponent,
    ActiveTireInteractiveSvgComponent,
    TreadDepthComponent,
    TyreDataComponent,
    TyreSeasonComponent,
    EmptyStateComponent,
    VehicleTyreDefaultsSegmentComponent,
    VehicleTyreDefaultsProducerComponent,
    VehicleTyreDefaultsTypeComponent,
    TextareaWithLimitationComponent,
    TyreParamsComponent,
    TyreTypeIconComponent,
    ExpectedDatePickerComponent,
    NewTyreBadgeComponent,
    ToTyreStateResourcePipe,
    DataCyDirective,
    AnchorTargetDirective,
    DndDirective,
    PreventDoubleClickDirective,
    TodoDirective,
    CollectiveCheckboxComponent,
    DotInputComponent,
    FourWheelsActiveTireInteractiveSvgComponent,
    SixWheelsActiveTireInteractiveSvgComponent,
    DepositActionStatusComponent,
    OrderStatusComponent,
    DepositPaymentStatusComponent,
    AddressWithCoordinatesComponent,
    CoordinatesComponent,
    HeaderAndBoldDataComponent,
    GenericModalComponent,
    RimTypeSelectComponent,
    VehicleTypeSingleSelectComponent,
    MarginComponent,
    DiscountComponent,
    PrintComponent,
    PrintOrEmptyPrintComponent,
    TyreInfoComponent,
    SkipNavigationOnSelectionDirective,
    CounterComponent,
    CompleteWheelComponent,
    ProductDataComponent,
    CompletionTypeComponent,
    CopyToClipboardDirective,
    ScrollTargetDirective,
    TableLoaderOrEmptyStateComponent,
    PaginationAboveTableComponent,
    PaginationUnderTableComponent,
    FullWheelLabelComponent,
    DetailsLoaderStateComponent,
    GeneralContentStateComponent,
    VehicleHistoryTabComponent,
    VehicleHistoryTabPropertiesComponent,
    VehicleSearchInputComponent,
    BlueGradientBadgeComponent,
    DayCardComponent,
    DropAreaComponent,
    MonthSelectorComponent,
    TimelineItemComponent,
    LinkComponent,
  ],
  imports: [
    CommonModule,
    WchfsUiModule,
    RouterModule,
    MatDialogModule,
    SelectModule,
    PsoTypeaheadModule,
    PsoButtonModule,
    DotLoaderModule,
    ModalModule,
    FullWidthSeparatorModule,
    HeadingModule,
  ],
  exports: [
    PsoButtonModule,
    TopActionsBarComponent,
    RecordsInfoComponent,
    TabPanelComponent,
    TabItemComponent,
    StatusComponent,
    RemarksComponent,
    AttachmentComponent,
    StepsComponent,
    EllipsisComponent,
    SortableHeaderComponent,
    InputSuffixComponent,
    SeparatorComponent,
    FiltersSectionComponent,
    FiltersSelectComponent,
    VipBadgeComponent,
    ButtonWarnArrowLeftComponent,
    ButtonWarnArrowRightComponent,
    ButtonDropdownComponent,
    PriceComponent,
    RimTypeComponent,
    AttachmentsOrEmptyAttachmentComponent,
    TirePositionDesignationComponent,
    TreadDepthInputComponent,
    NotifySnackbarComponent,
    TyreFuelEfficientComponent,
    TyreAdhesionComponent,
    TyreNoiseLevelComponent,
    TableNoEntriesComponent,
    ActiveOrInactiveStatusIconComponent,
    FleetPlateComponent,
    BadgeComponent,
    DragAndDropComponent,
    AddressComponent,
    SelectReadonlyComponent,
    ModalTextComponent,
    UserRolesComponent,
    ActiveTireInteractiveSvgComponent,
    TreadDepthComponent,
    TyreDataComponent,
    TyreSeasonComponent,
    EmptyStateComponent,
    VehicleTyreDefaultsSegmentComponent,
    VehicleTyreDefaultsProducerComponent,
    VehicleTyreDefaultsTypeComponent,
    TextareaWithLimitationComponent,
    TyreParamsComponent,
    TyreTypeIconComponent,
    ExpectedDatePickerComponent,
    NewTyreBadgeComponent,
    ToTyreStateResourcePipe,
    DataCyDirective,
    AnchorTargetDirective,
    DndDirective,
    PreventDoubleClickDirective,
    TodoDirective,
    SelectModule,
    PsoTypeaheadModule,
    CollectiveCheckboxComponent,
    DotInputComponent,
    FourWheelsActiveTireInteractiveSvgComponent,
    SixWheelsActiveTireInteractiveSvgComponent,
    DepositActionStatusComponent,
    DotLoaderModule,
    OrderStatusComponent,
    DepositPaymentStatusComponent,
    AddressWithCoordinatesComponent,
    CoordinatesComponent,
    HeaderAndBoldDataComponent,
    RimTypeSelectComponent,
    VehicleTypeSingleSelectComponent,
    MarginComponent,
    DiscountComponent,
    PrintComponent,
    PrintOrEmptyPrintComponent,
    TyreInfoComponent,
    SkipNavigationOnSelectionDirective,
    CounterComponent,
    CompleteWheelComponent,
    ProductDataComponent,
    CompletionTypeComponent,
    CopyToClipboardDirective,
    ScrollTargetDirective,
    TableLoaderOrEmptyStateComponent,
    PaginationAboveTableComponent,
    PaginationUnderTableComponent,
    FullWheelLabelComponent,
    DetailsLoaderStateComponent,
    GeneralContentStateComponent,
    VehicleHistoryTabComponent,
    VehicleSearchInputComponent,
    FullWidthSeparatorModule,
    HeadingModule,
    VehicleHistoryTabPropertiesComponent,
    BlueGradientBadgeComponent,
    DayCardComponent,
    DropAreaComponent,
    MonthSelectorComponent,
    TimelineItemComponent,
    ModalModule,
    LinkComponent,
  ],
})
export class PsoUiModule {}
