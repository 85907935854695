import { FilterParams } from '@data/filtering/filtering.interface';
import { LaravelBoolean } from '@core/http/crud-model/laravel-boolean';
import { Id } from '@core/http/crud-model';
import { RolesGroupEnum } from '@core/Roles';
import { RolesGroupResource } from '@data/roles-group-resource/roles-group-resource.model';
import { UserResource } from '@data/users/users.model';

export interface MediaResource {
  id: number;
  name: string;
  description: string;
  is_assigned_to_all: boolean;
  size: string;
  mime_type: string;
  aggregate_type: string;
  extension: string;
  url: string;
  created_at: string;
  updated_at: string;
  additional_data: {
    date_from: string;
    date_to: string;
    filters: {
      fleet_id: Id;
    };
  };
  roles_groups: RolesGroupResource[];
  author: UserResource;
}

export interface MediaResourceWithLp extends MediaResource {
  lp: number;
}

export interface MediaPostRequest {
  file: File;
  assign_to_all: LaravelBoolean;
  description?: string;
}

export interface MediaPutRequest {
  assign_to_all: LaravelBoolean;
  description: string;
  assignments?: MediaAssignment[];
}

export interface MediaAssignment {
  model: MediaAssignmentModelValue;
  ids: Id[];
}

export type MediaAssignmentModelValue = 'workshop' | 'fleet';

export class MediaAssignmentModel {
  static WORKSHOP: MediaAssignmentModelValue = 'workshop';
  static FLEET: MediaAssignmentModelValue = 'fleet';
}

export interface MediaFilterParams extends FilterParams {
  date_from: string;
  date_to: string;
  fleet_id: string;
  report_type: string;
}

export type MediaRemoveModalActionSlug = 'undo' | 'continue';

export class MediaRemoveModalAction {
  public static UNDO: MediaRemoveModalActionSlug = 'undo';
  public static CONTINUE: MediaRemoveModalActionSlug = 'continue';
}

export type MediaTagValue =
  | 'tyre_export'
  | 'product_tyre_export'
  | 'estimate-attachment'
  | 'confirm-release-action-attachment'
  | 'order-with-reservation-suborders-export'
  | 'courier-label'
  | 'courier-protocol'
  | 'workshop-export'
  | 'vehicle-export'
  | 'estimate-export'
  | 'estimate-details-export'
  | 'disposition-export'
  | 'orders-report'
  | 'dispositions-cumulative-report'
  | 'dispositions-detailed-report'
  | 'relocations-report'
  | 'relocations-times-report'
  | 'tyres-v1-report'
  | 'tyres-v2-report'
  | 'utilization-report'
  | 'vehicles-seasonal-storage-report'
  | 'reservation-export'
  | 'vehicle-tyres-report';

export class MediaTag {
  static TYRE_EXPORT: MediaTagValue = 'tyre_export';
  static PRODUCT_TYRE_EXPORT: MediaTagValue = 'product_tyre_export';
  static ESTIMATE_ATTACHMENT: MediaTagValue = 'estimate-attachment';
  static CONFIRM_RELEASE_ACTION_ATTACHMENT: MediaTagValue = 'confirm-release-action-attachment';
  static ORDER_WITH_RESERVATION_SUBORDERS_EXPORT: MediaTagValue = 'order-with-reservation-suborders-export';
  static COURIER_LABEL: MediaTagValue = 'courier-label';
  static COURIER_PROTOCOL: MediaTagValue = 'courier-protocol';
  static WORKSHOP_EXPORT: MediaTagValue = 'workshop-export';
  static VEHICLE_EXPORT: MediaTagValue = 'vehicle-export';
  static ESTIMATE_EXPORT: MediaTagValue = 'estimate-export';
  static ESTIMATE_DETAILS_EXPORT: MediaTagValue = 'estimate-details-export';
  static DISPOSITION_EXPORT: MediaTagValue = 'disposition-export';
  static ORDERS_REPORT: MediaTagValue = 'orders-report';
  static DISPOSITIONS_CUMULATIVE_REPORT: MediaTagValue = 'dispositions-cumulative-report';
  static DISPOSITIONS_DETAILED_REPORT: MediaTagValue = 'dispositions-detailed-report';
  static RELOCATIONS_REPORT: MediaTagValue = 'relocations-report';
  static RELOCATIONS_TIMES_REPORT: MediaTagValue = 'relocations-times-report';
  static TYRES_V1_REPORT: MediaTagValue = 'tyres-v1-report';
  static TYRES_V2_REPORT: MediaTagValue = 'tyres-v2-report';
  static UTILIZATION_REPORT: MediaTagValue = 'utilization-report';
  static VEHICLES_SEASONAL_STORAGE_REPORT: MediaTagValue = 'vehicles-seasonal-storage-report';
  static RESERVATION_EXPORT: MediaTagValue = 'reservation-export';
  static VEHICLE_TYRES_REPORT: MediaTagValue = 'vehicle-tyres-report';

  static reports(): MediaTagValue[] {
    return [
      MediaTag.DISPOSITIONS_CUMULATIVE_REPORT,
      MediaTag.DISPOSITIONS_DETAILED_REPORT,
      MediaTag.ORDERS_REPORT,
      MediaTag.RELOCATIONS_REPORT,
      MediaTag.TYRES_V1_REPORT,
      MediaTag.TYRES_V2_REPORT,
      MediaTag.UTILIZATION_REPORT,
      MediaTag.VEHICLES_SEASONAL_STORAGE_REPORT,
      MediaTag.RELOCATIONS_TIMES_REPORT,
      MediaTag.VEHICLE_TYRES_REPORT,
    ];
  }
}
