import { Injectable } from '@angular/core';
import { EmptyParams } from '@app/@shared/utils/empty-params';
import { TyreState } from '@data/disposition-add-edit/disposition-add-edit.interfaces';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  ResetFilteringState,
  ResetValues,
  SetControlValues,
  SetPage,
  SetPerPage,
  SetSort,
  SetTab,
} from './filtering-state';
import { FilteringModel, FilteringModelValue, PaginationParams, SortingParams, TabParams } from './filtering.interface';
import { DateTime } from 'luxon';
import { FleetInvoiceTab } from '../invoices/fleet-invoices/fleet-invoices.model';
import { DispositionStatus } from '@data/dispositions/dispositions.model';
import { MediaTag } from '@data/media/media.model';

const DEFAULTS: FilteringModel = {
  requests: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'id.desc',
    with: 'fleet,vehicle,workshop,items,auths,wholesaler,media,cost_group',
    self_fleets: '0',
    extract_by_auth_status: 'declined',
    tab: '',
    date_from: DateTime.local().startOf('month').toISODate(),
    date_to: DateTime.local().toISODate(),
  },
  requests_workshop: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'id.desc',
    with: 'fleet,vehicle,workshop,items,auths,wholesaler,media,cost_group',
    self_fleets: '0',
    extract_by_auth_status: 'declined',
    tab: '',
    date_from: DateTime.local().startOf('quarter').toISODate(),
    date_to: DateTime.local().toISODate(),
  },
  requests_invoice_mode: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'id.desc',
    with: 'fleet,vehicle,workshop,items,auths,wholesaler,media,cost_group',
    self_fleets: '0',
    extract_by_auth_status: 'declined',
    tab: '',
  },
  requests_status_mode: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'id.desc',
    with: 'fleet,vehicle,workshop,items,auths,wholesaler,media,cost_group',
    self_fleets: '0',
    extract_by_auth_status: 'declined',
    tab: '',
  },
  fleets: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: '',
    tab: '',
  },
  services: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: '',
    category: '',
    name: '',
    code: '',
    active: '',
    tab: '',
  },
  authorization: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'id.desc',
    with: 'invoice,fleet,vehicle,workshop,items,auths,cost_group,user,media',
    registration_number_or_order_ID: '',
    tab: '',
    date_from: DateTime.local().startOf('month').toISODate(),
    date_to: DateTime.local().toISODate(),
  },
  authorization_tab_mode: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'id.desc',
    with: 'invoice,fleet,vehicle,workshop,items,auths,cost_group,user,media',
    registration_number_or_order_ID: '',
    tab: '',
  },
  vehicles: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'id.desc',
    with: 'fleet,driver,tyres,fittedTyres,lastActivity,latestTask,tyreDefaults',
    tab: '',
  },
  deposits: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'id.desc',
    tyre_state: TyreState.DEPOSIT,
    tab: '',
  },
  release_actions: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'id.desc',
    tab: '',
    id: '',
  },
  orders_with_reservation: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'id.desc',
    tab: 'new',
  },
  product_tyres: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'id.desc',
    tab: '',
  },
  product_tyres_psoa: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'id.desc',
    tab: 'all',
  },
  relocations: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'id.desc',
    tab: '',
    created_at_from: DateTime.local().startOf('month').toISODate(),
    created_at_to: DateTime.local().toISODate(),
  },
  relocations_workshop: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'id.desc',
    tab: '',
    created_at_from: DateTime.local().startOf('quarter').toISODate(),
    created_at_to: DateTime.local().toISODate(),
  },
  notifications: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'id.desc',
    tab: '',
  },
  system_notifications: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'id.desc',
    tab: '',
  },
  files: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'created_at.desc',
    tab: '',
    exclude: 'reports',
  },
  users: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'id.desc',
    tab: '',
  },
  workshops: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'id.desc',
    tab: '',
  },
  invoices_panel: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'id.desc',
    tab: 'previous_period',
    date_from: null,
    date_to: null,
  },
  estimates: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'year.desc,month.desc,workshop_code.asc,number.desc',
    tab: '',
    created_at_from: DateTime.local().startOf('month').toISODate(),
    created_at_to: DateTime.local().toISODate(),
  },
  estimate_groups: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'year.desc,month.desc',
    tab: '',
  },
  fleet_invoices: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'year.desc,number.desc',
    tab: FleetInvoiceTab.F0_REGISTRY,
    sale_from: DateTime.local().startOf('month').toISODate(),
    sale_to: DateTime.local().toISODate(),
  },
  fleet_invoices_afl: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'year.desc,number.desc',
    tab: FleetInvoiceTab.F0_REGISTRY,
  },
  invoice_details: {
    page: 1,
    per_page: 5,
    paginate: '1',
    sort: 'id.desc',
    tab: '',
  },
  invoice_details_extended_dispositions: {
    page: 1,
    per_page: 10,
    paginate: '1',
    sort: 'id.desc',
    tab: '',
    vehicles: '',
    number: '',
    fleets: '',
    date_from: '',
    date_to: '',
    price_from: null,
    price_to: null,
    authorization_status: '',
    self_fleets: '0',
  },
  recipients: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'id.desc',
    tab: '',
  },
  stats: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'id.desc',
    date_from: DateTime.local().startOf('month').toISODate(),
    date_to: DateTime.local().toISODate(),
    disposition_status: DispositionStatus.CLOSED,
  },
  producers: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'name.asc',
    tab: '',
  },
  reports: {
    page: 1,
    per_page: 15,
    paginate: '1',
    sort: 'created_at.desc',
    tab: '',
    tags: MediaTag.reports().join(','),
    date_from: DateTime.local().startOf('month').toISODate(),
    date_to: DateTime.local().toISODate(),
    report_type: 'dispositions_stats',
  },
  reservations_pso_not_taken: {
    page: 1,
    per_page: 15,
    paginate: '1',
    tab: 'not_taken',
  },
  reservations_workshop_online_approved: {
    page: 1,
    per_page: 15,
    paginate: '1',
    tab: 'today',
  },
  reservations_workshop_offline_approved: {
    page: 1,
    per_page: 15,
    paginate: '1',
    tab: 'today',
  },
  reservations_pso_approved: {
    page: 1,
    per_page: 15,
    paginate: '1',
    tab: 'all',
  },
  reservations_fleet_admin: {
    page: 1,
    per_page: 15,
    paginate: '1',
    tab: 'confirmed',
  },
  reservations_workshop_offline_not_taken: {
    page: 1,
    per_page: 15,
    paginate: '1',
    tab: 'not_taken',
  },
};

@State<FilteringModel>({
  name: 'filtering',
  defaults: DEFAULTS,
})
@Injectable()
export class FilteringState {
  @Action(SetPerPage)
  setPerPage(ctx: StateContext<FilteringModel>, action: SetPerPage): void {
    ctx.setState(
      patch({
        [action.route]: patch({
          page: 1,
          per_page: action.perPage,
          sort: ctx.getState()[action.route].sort,
        }),
      }),
    );
  }

  @Action(SetPage)
  setPage(ctx: StateContext<FilteringModel>, action: SetPage): void {
    ctx.setState(
      patch({
        [action.route]: patch({
          page: action.page,
          per_page: ctx.getState()[action.route].per_page,
          sort: ctx.getState()[action.route].sort,
        }),
      }),
    );
  }

  @Action(SetSort)
  setSort(ctx: StateContext<FilteringModel>, action: SetSort): void {
    ctx.setState(
      patch({
        [action.route]: patch({
          page: ctx.getState()[action.route].page,
          per_page: ctx.getState()[action.route].per_page,
          sort: action.sort,
        }),
      }),
    );
  }

  @Action(SetControlValues)
  setControlValues(ctx: StateContext<FilteringModel>, action: SetControlValues): void {
    const page = 1;
    const updatedValues = { ...action.values, page };
    ctx.setState(
      patch({
        [action.route]: {
          ...ctx.getState()[action.route],
          ...updatedValues,
        },
      }),
    );
  }

  @Action(ResetValues)
  resetValues(ctx: StateContext<FilteringModel>, action: ResetValues): void {
    ctx.setState(
      patch({
        [action.route]: new EmptyParams(DEFAULTS[action.route]).remove(),
      }),
    );
  }

  @Action(SetTab)
  setTab(ctx: StateContext<FilteringModel>, action: SetTab): void {
    ctx.setState(
      patch({
        [action.route]: patch({
          tab: action.tab,
          id: null,
        }),
      }),
    );
  }

  @Action(ResetFilteringState)
  reset(ctx: StateContext<FilteringModel>): void {
    ctx.setState(DEFAULTS);
  }

  static params(
    route: string,
  ): (
    state: FilteringState,
  ) => Record<keyof PaginationParams | keyof SortingParams | keyof TabParams, FilteringModelValue> {
    return createSelector([FilteringState], (state: FilteringState) => {
      return new EmptyParams(state[route]).remove();
    });
  }
}
