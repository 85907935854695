import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { GenericTableColumn, GenericTableColumnTheme } from '@pso-ui/generic-table/generic-table.model';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class GenericTableComponent<T> {
  @Input() data: T[];
  @Input() dropArea: any;

  @HostBinding('class')
  @Input()
  theme: GenericTableColumnTheme = GenericTableColumnTheme.FILLED;
  @Input() isDraggable: boolean;

  get selection(): SelectionModel<T> {
    return this._selection;
  }

  @Input()
  set selection(value: SelectionModel<T>) {
    this._selection = value;
    this.columns = this._columns;
  }

  get columns(): GenericTableColumn[] {
    return this._columns;
  }

  @Input()
  set columns(value: GenericTableColumn[]) {
    if (!value) {
      return;
    }
    if (this._selection) {
      this.visibleColumns = ['select', ...value.map((c) => c.getName())];
    } else {
      this.visibleColumns = value.map((c) => c.getName());
    }
    this._columns = value;
  }

  private _columns: GenericTableColumn[];
  private _selection: SelectionModel<T>;
  visibleColumns: string[];
  selectColumnWidthInPx = 80;
  protected readonly GenericTableColumn = GenericTableColumn;

  collectiveCheckboxChanged(checked: boolean, data: T[]): void {
    if (checked) {
      this._selection.select(...data);
    } else {
      this._selection.clear();
    }
  }

  rowCheckboxChanged(checked: boolean, model: T): void {
    if (checked) {
      this._selection.select(model);
    } else {
      this._selection.deselect(model);
    }
  }
}
